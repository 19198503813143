.papers{
    width: 100%;
    height: 50vh;
}

.ban{
    position: relative;
    width: 100%;
    height: 100%;
}

.imgz{
    width: 100%;
    height: 100%;
}

.ban h1{
    font-size: 2vw;
    position: absolute;
    width: 100%;
    height: fit-content;
    text-align: center;
    top: 80%;
    color: white;
    text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
}

@media only screen and (max-width: 600px){
     .ban h1{
        font-size: 3.5vw;
     }   
}