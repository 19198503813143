.App{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: black;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}
.App::-webkit-scrollbar {
  width: 5px;
}

.App::-webkit-scrollbar-track {
  background-color:  rgba(217, 217, 230, 0.5);
  border-radius: 100px;
}

.App::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 100px;
}


.searching{
    width: 70%;
    height: 10vh;
    display: flex;
    border-radius: 15px;
    border: 1px solid rgb(21, 21, 105) ;
    background-color:  rgba(8, 8, 8, 0.5);
    position: absolute;
    top: 44vh;
    left: 15%;
    z-index: 5;    
}

#mini{
    position: absolute;
    top: -3.75vh;
    left: 1.5vw;
    background-color: black;
    color: blanchedalmond;
    width: fit-content;
    font-size: 1vw;
    height: fit-content;
    border-radius: 10px;
    border: 1px solid red;
    padding: 2%;
}
.pay{
    width: fit-content;
    color: white;
    font-weight: 100;
    font-size: 2rem;
    border-radius: 10px;
    align-self: center;
    padding: 2%;
    background-color: blueviolet;
}

.select-menu{
    height: fit-content;
    width: 29%;
    padding: 1%;
    position: relative;    
}

.overflows{
    height: fit-content;
    width: 100%;
    position: absolute;
    z-index: 5;
    top: 8vh;
    left: 0.5vw;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    
    
    
}
.overflows::-webkit-scrollbar {
    width: 5px;
  }
  
  .overflows::-webkit-scrollbar-track {
    background-color:  rgba(217, 217, 230, 0.5);
    border-radius: 100px;
  }
  
  .overflows::-webkit-scrollbar-thumb {
    background-color: blue;
    cursor: pointer;
    border-radius: 100px;
  }
  


.select-button{
    margin-top: 0%;
    cursor: pointer;
    padding-left: 0.5%;
    padding-right: 0.5%;

    width: 100%;
    padding-top: 3%;
    text-align: center;
    height: 5vh;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    background-color:  rgba(247, 247, 252, 0.3);
    border: 1px solid rgb(21, 21, 105);
}
.select-button span{
    font-size: 1.25vw;
    padding-left: 1%;
    color: #f2f2f2;
    text-overflow: ellipsis;
    
}
.select-button.active .down{
    transform: rotate(180deg);
}

.list{
    
    width: 100%;
    height: fit-content;
    top: -4%;

    margin-left: -2.5vw;
     
    
}
.options{

    padding-left: 1%;    list-style: none;
    text-decoration-style: none;
    width: 90%;
    font-size: 1.25vw;
    height: 4vh;
    background-color:  rgba(247, 247, 252, 0.5);
    border: 1px solid rgb(21, 21, 105);
    cursor: pointer;
    transition: 0.1s ease-in;
    transform-origin: top left;
    transform: translatex(-100px);
    margin-top: -5vh;
    scale: 0;
    opacity: 0;
    transition-delay: calc(var(--i) * 0.05s)
}
.options2{
    padding-left: 1%;    list-style: none;
    text-decoration-style: none;
    text-overflow: elipsis;
    width: 90%;
    font-size: 1.25vw;
    height: 4vh;
    background-color:  grey;
    border: 1px solid rgba(21, 21, 105, 0.5);
    cursor: pointer;
    transition: 0.1s ease-in, hover 0 ;
    transform-origin:  left top;
    transform: translatex(0);
    margin-top: 0;
    opacity: 1;
    scale: 1;
    overflow: hidden;
    transition-delay: calc(var(--i) * 0.1s);
    

}


.options2:hover{
    background: #f2f2f2;

}

.options2:nth-child(1){
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.options2:last-child{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.round{
    margin-top: 2vh;
    cursor: pointer;
    width: 4vw;
    height: 6vh;
    border-radius: 10px;
    border: 1px solid rgb(21, 21, 105);
    background-color:  rgba(247, 247, 252, 0.3);
}
@media only screen and (max-width: 1290px){
    .round{
        margin-top: 1vh;
        
    }
}

@media only screen and (max-width: 1000px){


    .overflows{
       top: 3vh;        
    }
    .select-button{
        height: 3vh;
    }
    .select-button span{
        font-size: 2vw;
    }
    .options2{
        height: 2.5vh;
        font-size: 2vw;
        padding-top: 0;
        padding-bottom: 0;
    }
    .round{
        height: 3.5vh;
        margin-top: 0.5vh;
        width: 5vw;
    
    }
    .searching{
        top: 35vh;
        left: 10%;
        width: 80%;
        height: fit-content;

    }
}
@media only screen and (max-width: 800px){
    .App::-webkit-scrollbar {
        width: 0;
    }
    .overflows::-webkit-scrollbar {
        width: 0;
      }
     
           
        
}
     
@media only screen and (max-width: 600px){

     .searching{
        height: 7vh;
        width: 90%;
        left: 5%;
        top: 40vh;
     }
     .select-button{
        height: 4vh;
    }
    .select-button span{
        font-size: 2vw;
        padding-left: 3%;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .round{
        height: 5vh;
        padding-right: 3%;
    }
    .overflows{
        left: -5%;
    }
    .options2{
        font-size: 3vw;
        padding-top: 2%;
        padding-bottom: 2%;
        margin-left: -5vw;
        white-space: nowrap;
        text-overflow: ellipsis;
     }
}    

@media only screen and (max-width: 450px){
     .round{
        height: 3.5vh;
        padding-top: 2%;
        padding-right: 4%;
     }
     .searching{
        height: 6vh;
        width: 96%;
        left: 2%;
     }
     .select-button{
        padding-top: 10%;
        height: 3vh;
     }
     .select-button span{
        font-size: 3vw;
        white-space: nowrap;
        text-overflow: ellipsis;
     }
     .options2{
        font-size: 3vw;
        padding-top: 2%;
        padding-bottom: 2%;
        margin-left: -5vw;
        white-space: nowrap;
        text-overflow: ellipsis;
     }
}

@media only screen and (max-width: 380px){
    
    .round{
        height: 4vh;
        padding-top: 1%;
        padding-right: 4%;
    }
    .options2{
        margin-left: -7vw;
    }

    .list{
        margin-left: 0;
     }   
}

@media only screen and (max-width: 370px){

    .searching{
        top: 35vh;
    }
}

@media only screen and (max-width: 300px){
    .round{
        height: 4vh;
        padding-top: 1%;
        padding-right: 6%;
    }
}