.maps{
    width: 50%;
    height: 50vh;
    background-color: black;
}


@media only screen and (max-width: 600px){
    .maps{
        width: 100%;
    }
}
