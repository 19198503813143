.bill{
    width: 100%;
    height: fit-content;
    position: relative;
    
    
}

.see{
    height: 50vh;
    width: 100%;
    background-color: red;
}

.linki{
    text-decoration: none;
    
}

.nav{
  position: absolute;
  width: 65%;
  height: fit-content;
  top: 32vh;
  left: 17.5%;
  display: flex;
  justify-content: space-between;
  z-index: 5;
  
}
.nax{
    color: white;
    padding-bottom: 2%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;  
    font-size: 1.25vw;
    letter-spacing: 0.2vw;
    
}

.bridge{
    width: 100%;
    height: 10vh;
    position: absolute;
    top: 20vh;
    left: 0;
    z-index: 500;
}

.navy{
   width: fit-content;
   color: aliceblue;
   font-size: 1.5vw;
   transition: 0.5s ease-in-out;
   cursor: pointer;
   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;   
}
.topz{
    width: fit-content;
    height: fit-content;
    font-size: 3vw;
    margin-bottom: -4vh;
}

.navy2{
    border-bottom: none;
    position: relative;
}

.navy2:hover::after{
    transform: scale(1);
}
.cover{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding-bottom: 0;
    height: fit-content;
    
}
.cover::after{
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 0.2vh;
    background-color:  rgba(233, 17, 28, 0.5);
    transform: scale(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
}
.topIcons{
    
    color: white;
    font-size: "small";
}

.karibu{
    color: white;
    margin-top: 5%;
    font-size: 1.75vw;
    width: 100%;
    text-align: center;
    height: fit-content;
}
.binya{
    color: white;
    margin-top: 5%;
    font-size: 1.5vw;
    width: 100%;
    text-align: center;
    height: fit-content;
}

.ingia{
    margin-top: 10%;
    margin-left: 25%;
    margin-bottom: 5%;
    width: 50%;
    font-size: 1.5vw;
    height: fit-content;
    padding: 2%;
    color: white;
    border-radius: 15px;
    border: 1px solid  rgba(4, 4, 252, 0.5);
    background-color:  rgba(253, 253, 255, 0);
    transition: 0.5s ease-in;
    cursor: pointer;
}

.ingia:hover{
    background-color:  rgba(253, 253, 255, 0.5);
}


@media only screen and (max-width: 1000px){
    .navy{
        font-size: 2.5vw;
       padding-bottom: 1%;
    }
    .topIcons{
        font-size: 1vw;
    }
    .topz{
        font-size: 3.5vw;
        margin-bottom: -2vh;
    }
    .nav{
        top: 22vh;
        width: 80%;
        left: 10%;
    }
    .nax{
        font-size: 3.5vw;
        padding-bottom: 1%;
    }
}

@media only screen and (max-width: 600px){
    .navy{
        font-size: 3vw;
    }
    .topIcons{
        font-size: 1vw;
    }
    .topz{
        font-size: 5vw;
        margin-bottom: -1.5vh;
    }
    .nav{
        top: 32vh;
        width: 90%;
        left: 5%;
    }
    .nax{
        font-size: 3.5vw;
    }
}


@media only screen and (max-width: 450px){
    .navy{
        font-size: 3.75vw;
        
    }
    
}
@media only screen and (max-width: 370px){
    .navy{
        font-size: 3vw;
    }
    .topIcons{
        font-size: 1vw;
    }
    .topz{
        font-size: 5vw;
        margin-bottom: -1.5vh;
    }
    .nav{
        top: 26vh;
        width: 90%;
        left: 5%;
    }
    .nax{
        font-size: 3.5vw;
    }    
}

