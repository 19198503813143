.house{
    display: flex;
    width: 100%;
    height: fit-content;
    background-color: black;
    overflow-x: hidden;    
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.lefta{
    display: block;
   position: sticky;
   top: 8vh;
   width: 25%;
   height: fit-content;
}

.mido{
 display: block;
 border-left: 1px solid  rgba(3, 3, 247, 0.5);
 border-right: 1px solid  rgba(3, 3, 247, 0.5);
 width: 50%;
 height: 200vh; 
}

.raita{
    display: block;
    width: 20%;
    height: fit-content;
}

.wote{
    width: 98%;
    padding: 1%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
}

.portions{
    width: fit-content;
    display: flex;
    margin-right: 5%;
    margin-top: 5%;
}

.ico{
    color: red;
    font-size: 2vw;
}

.locals{
    width: 90%;
    height: fit-content;
    border-left: 1px solid  white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.loc{
    color: white;
    font-size: 1.5vw;
    margin-top: 0;
    padding-left: 2%;
    padding-right: 2%;
    white-space: nowrap;
    text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;

}

.simu{
    color: white;
    font-size: 1.5vw;
    width: 50%;
    text-align: left;
    padding-left: 2%;
    text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
}

.disc{
    margin-top: 2vh;
    width: 90%;
    height: fit-content;
    padding: 2%;
    margin-left: 5%;
    box-shadow: inset 0 -3em 3em rgba(253, 252, 252, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(28, 4, 240, 0.3);
}

.disc h2{
    color: white;
    font-size: 1.5vw;
    width: 100%;
    text-align: center;
}

.disc h4{
    color: antiquewhite;
    margin-top: 4%;
    font-size: 1.25vw;
    width: 100%;
    text-align: center;
}
.collar{
    width: fit-content;
    height: fit-content;
    padding-left: 2%;
}

@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.neighbourz {
    --size: 300px;

    --speed: 50s;

    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

    width: var(--size);

    height: var(--size);

    filter: blur(calc(var(--size) / 5));

    background-image: linear-gradient(hsl(158, 82, 57, 85%));

    animation: rotate var(--speed) var(--easing) alternate infinite;

    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

@media (min-width: 720px){
    .gradient{
        --size: 50%;
    }
}
.nyumbas{
    width: 98%;
    padding: 1%;
    display: flex;

}

.nyumbas h2{
    margin-top: 0;
    color: white;
    font-size: 2vw;
    margin-left: 2%;
    white-space: nowrap;
}

.user{
    height: 4vh;
    width: 4vh;
    border-radius: 10px;
    box-shadow: inset 0 -3em 3em rgba(253, 252, 252, 0.1), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(28, 4, 240, 0.3);
}

@media only screen and (max-width: 600px){
   .mido{
    width: 100%;
    height: 100vh;
   }    
   .lefta{
      display: none;
   }
   .raita{
    display: none;
   }
   .loc{
    font-size: 2.5vw;
   }
   .simu{
    font-size: 3vw;
   }
   .nyumbas h2{
    font-size: 3vw;
   }
}




