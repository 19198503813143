.adds{
    width: 100%;
    height: fit-content;
    padding-bottom: 4%;
  
}
.kings{
    width: 100%;
    height: fit-content;
    display: flex;
}
.lin{
    text-decoration: none;
}

.sample{
    width: 48%;
    padding: 1%;
    height: 30vh;
    box-shadow: inset 0 -3em 3em rgba(253, 252, 252, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(28, 4, 240, 0.3);
    margin-top: 5%;
    background: rgb(180, 144, 177);
    background: radial-gradient(circle, rgba(180, 144, 177, 0.20833333333333337) 0%,
    rgba(238, 174, 202, 0.3846153846153846) 0%,
    rgb(38, 11, 236) 0%,
    rgba(28, 5, 99, 0.5),
    rgba(47, 74, 118, 0.5) 99%,
    rgba(19, 59, 105, 0) 100%);
    

}

.sample h3{
    color: white;
    font-size: 3vw;
    width: 100%;
    text-align: center;
}

.sample h5{
    color: white;
    font-size: 1.5vw;
    width: 100%;
    text-align: center;
    text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
}

.whites{
    margin-top: 15vh;
    width: 25%;
    height: 1vh;
    border-bottom: 0.25px solid white;
    border-top: 0.25px solid white;
    background-color: rgba(28, 4, 240, 0.5);
}

.blacks{
    margin-top: 15vh;
    width: 25%;
    height: 1vh;
    border-bottom: 0.25px solid white;
    border-top: 0.25px solid white;
    background-color: rgba(28, 4, 240, 0.5);
}

.wordz{
    width: 90%;
    margin-left: 5%;
    text-align: start;
    color: antiquewhite;
    font-size: 2vw;
}
.wasop{
    display: none;
    transition: 0.5s ease;
}
.wasap{
    position: fixed;
    z-index: 10;
    display: block;
    top: 80%;
    right: 1%;
    width: fit-content;
    height: fit-content;
    transition: 0.5s ease-in;
    animation: scrollTop 0.5s alternate ease infinite;
}

@keyframes scrollTop{
    from{
        transform: translateY(4px);
    }
    to{
        transform: translateY(-2px);
    }
}
@media only screen and (max-width: 600px){
    .sample{
        width: 60%;
        height: 20vh;
    }
    .whites{
        height: 0.5vh;
        width: 20%;
    }   
    .blacks{
        height: 0.5vh;
        width: 20%;
    }
    .kings{
        margin-top: 10%;
    }
    .sample h3{
        font-size: 4vw;
    }
    .sample h5{
        font-size: 3vw;
        line-height: 2.5vh;
    }
    .wordz{
        font-size: 3vw;
        line-height: 2.5vh;
    }
}