.down{
    color: white;
    font-size: 2w;
    margin-top: 1.5%;
    transition:  0.3s ease-in-out;
    
}
.ups{
    font-size: 2vw;
    color:  white;
    transform: rotate(180deg);
    transition: 0.5s ease-in;    
}

.search{
    color: white;
    font-size: 2vw;
    margin-left: 20%;
    margin-top: 15%;
}

@media only screen and (max-width: 1000px){
    .down{
       margin-top: -10%;
       font-size: 3vw;        
    }
    .ups{
         margin-top: -10%;
       font-size: 3vw;
    }
}

@media only screen and (max-width: 600px){
    .down{
        margin-top: -5%;
        font-size: 3vw;        
     }
     .ups{
          margin-top: -5%;
        font-size: 3vw;
     }
}
@media only screen and (max-width: 450px){
    .ups{
        margin-top: 0;
    }
    .down{
        margin-top: 0;
    }
}



