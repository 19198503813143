.heads{
    width: 100%;
    height: 8vh;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    border-bottom: 1px solid rgb(41, 26, 78);    
}

.linka{
    text-decoration: none;
    margin-left: 40%; 
}
.linka:hover{
    text-decoration: none;
}

.first{
    width: 98%;
    height: 8vh;
    display: flex;
    padding: 1%;
}

.meinu{
    color: whitesmoke;
    font-size: 2.5vw;
    margin-top: 0;
}
.favs{
  color: whitesmoke;
  margin-left: 30%;
  font-size: 2vw;
}
.avatar{
    width: 2vw;
    height: 4vh;
    border-radius: 50%;
    margin-left: 30%;
    margin-top: 0;
    background-color: aliceblue;
    cursor: pointer;
}

.lebo{
    white-space: nowrap;
    color: whitesmoke;
    margin-top: -4.5%;
    margin-left: -8%;
    width: 15vw;
    object-fit: contain;    
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.karibu{
    border-left: 4px solid rgba(4, 4, 252, 0.5);
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    background: rbg(33, 22, 228);
    background: linear-gradient(90deg, rgba(33, 22, 228, 0.2) 1%, 
    rgba(138, 138, 246, 0.17841877081455326) 53%, 
    rgba(117, 77, 255, 0) 100%);
    color: white;
    margin-top: 5%;
    font-size: 1.75vw;
    width: 100%;
    text-align: center;
    height: fit-content;
}
.binya{
    color: white;
    margin-top: 5%;
    font-size: 1.5vw;
    width: 100%;
    text-align: center;
    height: fit-content;
}

.ingia{
    margin-top: 10%;
    margin-left: 25%;
    margin-bottom: 5%;
    width: 50%;
    font-size: 1.5vw;
    height: fit-content;
    padding: 2%;
    color: white;
    border-radius: 15px;
    border: 1px solid  rgba(4, 4, 252, 0.5);
    background-color:  rgba(253, 253, 255, 0.2);
    transition: 0.5s ease-in;
    cursor: pointer;
}

.ingia:hover{
    background-color:  rgba(253, 253, 255, 0.5);
}

.blocks{
    height: 100vh;
    width: 50%;
    position: absolute;
    top: 8vh;
    left: 0;
    background-color: rgb(15, 10, 39);
    z-index: 20;
    transition-timing-function: 0.5s;
}
.blocks2{
    transition-timing-function: 0.5s;
    height: 100vh;
    width: 50%;
    position: absolute;
    top: 8vh;
    left: -120%;
    background-color: rgb(41, 26, 78);
    z-index: 20;
}
.optio{
    width: 90%;
    height: fit-content;
    margin-top: 0;
    padding-left: 1%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 2vw;
    text-align: start;
    cursor: pointer;
    
}

.ico{
    margin-top: 1.75%;
    color: white;
    font-size: 2.25vw;
    width: fit-content;
    height: fit-content;
}

.darkness{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 8vh;
    z-index: 15;
    transition: 0s;
    background-color: rgba(0, 0, 0, 0.7);
}
.darkness2{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: -120%;
    top: 8vh;
    z-index: 15;
    transition: 0s;
    background-color: rgba(0, 0, 0, 0.7);
}

.lin{
    text-decoration: none;
    color: white;
    transition: 0.5s ease-in;
    display: flex;
}

.lin:hover{
    color: blue;
    
}
.namba{
  margin-top: 1%;
 font-size: 1.5vw;
 color: white;
 text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;   
}

@media only screen and (max-width: 1020px){
    .heads{
        height: 6vh;
    }
    .darkness{
        top: 6vh;
    }
    .darkness2{
        top: 6vh;
    }
    .blocks{
        top: 6vh;
    }
    .blocks2{
        top: 6vh;
    }
    .lebo{
        font-size: 4vw;
    }
    .linka{
        margin-left: 25%;
    }
    .favs{
        margin-left: 15%;
        font-size: 5vw;
    }
    .meinu{
        font-size: 3vw;
    
    }
    .avatar{
        width: 6vw;
    }
}

 @media only screen and (max-width: 920px){
    .meinu{
        font-size: 5.5vw;
    }
}

 @media only screen and (max-width: 1020px){
  .lebo{
    width: 30vw;
    margin-left: 0;
    margin-top: 0;
  }    

}
@media only screen and (max-width: 600px){
     .lebo{
        width: 40vw;
     }
     .namba{
        margin-top: 3%;
     }
    .karibu{
        font-size: 4vw;
    }
    .binya{
        font-size: 3.5vw;
    }
    .ingia{
        font-size: 4vw;
    }
    .ico{
        font-size: 5.5vw;
    }
    .namba{
        font-size: 4vw;
    }
    .optio{
        font-size: 4.5vw;
    }
    .blocks{
        width: 70%;
        top: 5vh;
    }
    .blocks2{
        padding-left: 1%;
        width: 70%;
        top: 5vh;
    }
    .darkness{
        top: 5vh;
    }
    .darkness2{
        padding-left: 1%;
        top: 5vh;
    }
    .avatar{
        margin-top: 2%;
    }
    .meinu{
        margin-top: 1%;
    }

}
@media only screen and (max-width: 450px){
    
    .avatar{
        height: 3vh;
        margin-left: 20%;
        margin-top: 2%;
    }
    .heads{
        padding-top: 2%;
    }
    .meinu{
        font-size: 7vw;
    }
    .lebo{
        font-size: 5vw;
    }
    

}

@media only screen and (max-width: 370px){
    .heads{
        height: 4vh;
    }
    .meinu{
        font-size: 7vw;
        margin-top: 0;
    }
    .lebo{
        margin-top: -3%;
    }
    .avatar{
        margin-top: 0;
    }

    
}