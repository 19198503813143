.prof{
    width: 90%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y:auto;
    background-color:  rgb(19, 19, 41);
    position: absolute;
    right: 0;
    top: 8vh;
    z-index: 200;
    transition: 0.5s ease-in-out;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.prof2{
    width: 90%;
    height: 100vh;
    background-color:  rgb(19, 19, 41);
    position: absolute;
    right: -120%;
    top: 8vh;
    z-index: 200;
    transition: 0.5s ease-in;
}
.cus{
    width: 90%;
    text-align: center;
    color: white;
    font-size: 1.5vw;
    border-left: 4px solid rgba(4, 4, 252, 0.5);
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    background: rbg(33, 22, 228);
    background: linear-gradient(90deg, rgba(33, 22, 228, 0.2) 1%, 
    rgba(138, 138, 246, 0.17841877081455326) 53%, 
    rgba(117, 77, 255, 0) 100%);
}
.tops{
    display: flex;
    width: 100%;
}
.cros{
    color: white;
    cursor: pointer;
    font-size: 3vw;
    transition: 0.3s ease-in;
}
.cros:hover{
    color: blue;
}
.prof h3{
    border-left: 4px solid rgba(4, 4, 252, 0.5);
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-left: 4%;
    color: white;
    font-size: 1.4vw;
    width: 50%;
    text-align: left;
    background: rbg(33, 22, 228);
    background: linear-gradient(90deg, rgba(33, 22, 228, 1), 1%,
    rgba(138, 138, 246, 0.17841877081455326) 53%,
    rgba(99, 62, 229, 0.47115384615384615) 100% );
}
.dem{
    margin-top: 2%;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid rgba(242, 242, 248, 0.5);
    border-radius: 5px;
    margin-bottom: 4%;
}
.clicks{
    width: 40vw;
    height: fit-content;
}
.imo{
    width: 90%;
    margin-left: 5%;
    object-fit: contain;
}
.downs{
    background: rbg(33, 22, 228);
    background: linear-gradient(90deg, rgba(33, 22, 228, 1), 1%,
    rgba(138, 138, 246, 0.17841877081455326) 53%,
    rgba(99, 62, 229, 0.47115384615384615) 100% );
    width: 98%;
    padding-top: 2%;
    padding-left: 2%;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(247, 247, 252, 0.5);
}
.left{
    padding-top: 1%;
    width: 20%;
    display: flex;
    
}
.icon{
    color: white;
    font-size: 2vw;
}
.verbs{
    color: white;
    font-size: 1.5vw;
    margin-top: 0;
    white-space: nowrap;
}

.right{
    
    width: 40%;
    display: flex;
    justify-content: space-evenly;
}
.iconx{
    font-size: 2vw;
    color: red;
}
.leads{
    width: 96%;
    padding-left: 2%;
    height: fit-content;
    display: flex;

}

.leads h4{
    color: white;
    font-size: 1.5vw;
    width: 98%;
    text-align: start;
    margin-left: 2%;
    margin-top: 1vh;
}

.imagex{
    box-shadow: inset 0 -3em 3em rgba(253, 252, 252, 0.1), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(28, 4, 240, 0.3);
    width: 3vw;
    height: 6vh;
}

.next{
    padding-top: 1%;
    width: 30%;
    display: flex;
}

.anonymous{
    box-shadow: inset 0 -3em 3em rgba(51, 3, 162, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(255, 253, 253, 0.3);
    padding-top: 1%;
    position: fixed;
    z-index: 50;
    background-color: rgba(16, 16, 17, 0.5);
    top: 40vh;
    left: 20vw;
    width: 50%;
    height: fit-content;
    
    height: fit-content;
    border-radius: 10px;
    transform: translateY(0px) scale(1) ;
    opacity: 1;
    transition: 0.5s ease-in;
    transform-origin: top;
}
.anonymous2{
    position: fixed;
    z-index: 50;
    width: 0;
    height: 5vh;
    transform: translateY(-50px) scale(0.1) ;
    transition: 0.5s ease;
    transform-origin: top;
    opacity: 0;
    box-shadow: inset 0 -3em 3em rgba(253, 252, 252, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(255, 253, 253, 0.3);
}
.paperz{
    background-color: rgba(3, 3, 8, 0.7);
    position: fixed;
    z-index: 50;
    top: 40vh;
    left: 20vw;
    height: fit-content;
    width: 60vw;
    padding: 2vw;
    z-index: 30;
    border: 1px solid ;
    transform: translateY(0px) scale(1) ;
    box-shadow: inset 0 -3em 3em rgba(51, 3, 162, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(255, 253, 253, 0.3);
    border-radius: 8px;
    opacity: 1;
    transition: 0.5s ease-in;
    transform-origin: top; 
}
.paperz2{
    position: fixed;
    z-index: 50;
    background-color: rgba(3, 3, 8, 0.7);
    top: 40vh;
    left: 20vw;
    width: 0;
    height: 2vh;
    padding: 2vw;
    z-index: 30;
    border: 1px solid ;
    transform: translateY(-50px) scale(0.1) ;
    box-shadow: inset 0 -3em 3em rgba(51, 3, 162, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(255, 253, 253, 0.3);
    border-radius: 8px;
    opacity: 0;
    transition: 0.5s ease-in;
    transform-origin: top; 
}


.anonymous h4{
    width: 98%;
    font-size: 2vw;
    color: white;
    text-align: center;
    padding: 1%;
}
.inputz{
    width: 80%;
    margin-left: 8%;
    margin-top: 4%;
    border-radius: 10px;
    padding: 2%;
    font-size: 2.5vw;
    color: white;
    border: 1px solid black;
    background-color: rgba(106, 106, 243, 0.5);
}

.badilishaz{

    width: 48%;
    margin-left: 25%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 10%;
    padding: 1%;
    font-size: 2.5vw;
    color: white;
    background-color: rgba(2, 2, 131, 0.5);
    cursor: pointer;
    transition: 0.5s;
}
.clos{
    width: fit-content;
    height: fit-content;
    padding: 2%;
    border: 1px solid white;
    margin-left: 1%;
    cursor: pointer;
}
.badilishaz:hover{
    background-color: rgba(2, 2, 131, 0);
    border: 1px solid rgba(2, 2, 131, 0.5);
}

.sure{
    width: 100%;
    height: fit-content;

}

.sure h4{
    width: 100%;
    color: white;
    font-size: 2.5vw;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
}

.sure h3{
    width: 100%;
    color: white;
    font-size: 2vw;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
}


.buttns{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    margin-bottom: 4%;
}

.nope{
    padding: 2%;
   width: 30%;
   font-size: 3vw;
   background-color: red;
   border-radius: 15px;
   color: white;
   transition: 0.5s ease-in;
}
.nope:hover{
    background-color: rgba(255, 25, 0, 0.1);

}

.yap{
    transition: 0.5s ease-in;
padding: 2%;
background-color: green;
width: 30%;
font-size: 3vw;
border-radius: 15px;
color: white;
}
.yap:hover{
    background-color: rgba(8, 252, 73, 0.1);
}
.bold{
    width: fit-content;
    height: fit-content;
    display: flex;
    border: 1px solid gray;
    border-radius: 8px;
    padding-left: 2%;
    padding-top: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-right: 4%;
    transition: 0.5s ease-in;
    cursor: pointer;
}
.bold:hover{
    background-color: rgba(138, 138, 246, 0.17841877081455326);
}

.bold span{
    color: white;
    font-size: 1.25vw;
}
.modo{
    background-color: black;
    border: 1px solid blue;
    border-radius: 10px;
    position: absolute;
    top: 40%;
    left: 30%;
    width: 40%;
    height: fit-content;
}
.karibuz{
    border-left: 4px solid rgba(4, 4, 252, 0.5);
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    background: rbg(33, 22, 228);
    background: linear-gradient(90deg, rgba(33, 22, 228, 0.2) 1%, 
    rgba(138, 138, 246, 0.17841877081455326) 53%, 
    rgba(117, 77, 255, 0) 100%);
    color: white;
    margin-top: 5%;
    font-size: 1.75vw;
    width: 100%;
    text-align: center;
    height: fit-content;
}
.binyaz{
    color: white;
    margin-top: 5%;
    font-size: 1.5vw;
    width: 100%;
    text-align: center;
    height: fit-content;
}

.ingia{
    margin-top: 10%;
    margin-left: 25%;
    margin-bottom: 5%;
    width: 50%;
    font-size: 1.5vw;
    height: fit-content;
    padding: 2%;
    color: white;
    border-radius: 15px;
    border: 1px solid  rgba(4, 4, 252, 0.5);
    background-color:  rgba(253, 253, 255, 0.2);
    transition: 0.5s ease-in;
    cursor: pointer;
}

.ingia:hover{
    background-color:  rgba(253, 253, 255, 0.5);
}




@media only screen and (max-width: 600px){
    .cus{
        font-size: 4vw;
        text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
    }
    .cros{
        font-size: 4vw;
    }
    .imagex{
        width: 10vw;
        height: 4vh;
    }
    .cros{
        font-size: 4vw;
    }
    .prof h3{
        font-size: 3vw;
    }
    .verbs{
        font-size: 3vw;
    }
    .leads h4{
        font-size: 4vw;
    }
    .anonymous h4{
        font-size: 4vw;
    }
    .inputz{
        font-size: 4vw;
    }
    .nope{
        font-size: 5vw;
    }
    .yap{
        font-size: 5vw;
    }
    .dem{
        width: 95%;
    }
    .anonymous{
        width: 70%;
        left: 15vw;
    }
    .clicks{
        width: 100%;
    }
    .prof{
        top: 6vh;
    }
    .badilishaz{
        font-size: 4vw;
    }
    .bold span{
        font-size: 3vw;

    }
    .right{
        width: 45%;
    }

    .modo{
        width: 70%;
        left: 15%;
    }
    .karibuz{
        font-size: 4vw;
    }
    .binyaz{
        font-size: 3.5vw;
    }
    .ingia{
        font-size: 4vw;
    }
    
    
}
