
  .loader{
    width: 100%;
    height: fit-content;
    padding: 0;
    background-color: black;
    box-sizing: border-box;
    margin-bottom: 4%;
  }
  .center {
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
  }
  
  .wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, cyan, #fff);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  
  .wave:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .wave:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  .wave:nth-child(4) {
    animation-delay: 0.6s;
  }
  
  .wave:nth-child(5) {
    animation-delay: 0.8s;
  }
  
  .wave:nth-child(6) {
    animation-delay: 1s;
  }
  
  .wave:nth-child(7) {
    animation-delay: 1.2s;
  }
  
  .wave:nth-child(8) {
    animation-delay: 1.4s;
  }
  
  .wave:nth-child(9) {
    animation-delay: 1.6s;
  }
  
  .wave:nth-child(10) {
    animation-delay: 1.8s;
  }
  
  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }