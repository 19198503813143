.try{
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}


.select_menu{
    width: 30vw;
    margin: 15vw auto;
}


.select_button{
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: 5vh;
    background-color: #fff;
    color: #333;
    padding: 2%;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: 1s ease-in-out;
}


.arrow{
    font-size: 2vw;
    transition: transform 1s ease-in;
}
.active {
    font-size: 2vw;
     transform: rotate(180deg);
   transition: 1s ease-in-out;
}


.list{
    position: absolute;
    width: 29vw;
    margin-top: 10px;
    transform: translateY(0) translateX(-40px) ;    
    height: fit-content;
}


.option{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5vh;
    padding: 1%;
    background-color: aliceblue;
    transition: 0.5s ease-in-out;
    transform-origin: top;
    transform: translateY(-30px);
    margin-bottom: -30px;    
    scale: 0;
    transition-delay: calc(var(--i) * 0.1s);
}
.option2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5vh;
    padding: 1%;
    background-color: aliceblue;
    transition: 0.5s ease-in-out;
    transform-origin: top;
    transform: translateY(0);
    margin-bottom: 0;    
    scale: 1;
    transition-delay: calc(var(--i) * 0.1s);
}



.option:hover{
    background-color: #f2f2f2;
}


.list .option:nth-child(1){
    border-top-left-radius: 8px;
}
