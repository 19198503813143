.papers{
    width: 100%;
    height: 60vh;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-top: 0;    
}

.ban{
    width: 100%;
    height: 100%;
    position: relative;
}

.imgz{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center center;    
}

.imeji{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    background-color:  rgba(5, 5, 5, 0.5);
}

.bans{
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: fit-content;
    z-index: 10;
}

.name{
    margin-top: 6%;
    width: 100%;
     font-size: 1.75vw;
    text-align: center;
   color: antiquewhite;  
}
.maneno{
    
   width: 100%;
    text-align: center;
   color: aliceblue;
   font-size: 1.5vw;
}

.imgxx{
    width: 30vw;
    height: 35vh;
}
@media only screen and (max-width: 1000px){
    .maneno{
        
        font-size: 2.5vw;
        line-height: 2vh;
     }
     .name{
         font-size: 3.5vw;
         color: antiquewhite;
        
     }
     .papers{
         height: 70vh;
     }
     .imeji{
         height: 65vh;
     }
     
}

@media only screen and (max-width: 600px){
    .maneno{
        
       font-size: 3vw;
       line-height: 2vh;
    }
    .name{
        font-size: 4vw;
        color: antiquewhite;
        margin-top: 5%;
    }
    .papers{
        height: 45vh;
        margin-top: 7vh;
    }
        
}

@media only screen and (max-width: 450px){
       .maneno{
        font-size: 3.5vw;
       }
}

@media only screen and (max-width: 370px){
    .papers{
        margin-top: 0;
    }
}