.Footer{
    width: 100%;
    height: fit-content;
    background-color: black;
    border-top: 1px solid  rgba(15, 15, 236, 0.5);    
}

.futi{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.iconas{
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    height: fit-content;
}

.icona{
    color: white;
    font-size: 2.5vw;
    cursor: pointer;

}

.marks{
    
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.mark{
    width: 10vw;
    text-align: center;
    font-size: 1.75vw;
    color: white;
    cursor: pointer;
}

.tag{
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;

}

.company{
    margin-top: 4%;
    width: 100%;
    text-align: center;
    color: white;
    letter-spacing: 0.1vw;
    font-size: 2vw;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.copy{
    color: white;
    font-size: 1vw;
    width: 100%;
    text-align: center;
    margin-top: 4%;
}
.lagos{
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
}
.lagos h4{
    color: white;
    font-size: 1.75vw;
    width: 100%;
    text-align: center;
    margin-top: 4vh;
}
.master{
    width: 15vw;
    height: 15vh;
    margin-left: 42.5vw;
}
.linc{
    text-decoration: none;
}
@media only screen and (max-width: 600px){
    .marks{
        width: 100%;
        text-align: center;
        
        flex-direction: column;
    }

    .mark{
        width: 100%;
        text-align: center;
        white-space: nowrap;
        font-size: 4vw;
    }

    .iconas{
        padding-top: 4%;
    }

    .copy{
        font-size: 3vw;
    }
    .lagos h4{
        font-size: 4vw;
    }
    .master{
        width: 30vw;
        height: 8vh;
        margin-left: 35vw;
    }
    .company{
        font-size: 5vw;
    }   
}








