.card{
    margin-top: 2%;
    background: rgb(180, 144, 177);
    background: radial-gradient(circle, rgba(180, 144, 177, 0.20833333333333337) 0%,
    rgba(238, 174, 202, 0.3846153846153846) 0%,
    rgb(38, 11, 236) 0%,
    rgba(28, 5, 99, 0.5),
    rgba(47, 74, 118, 0.5) 99%,
    rgba(19, 59, 105, 0) 100%);
    box-shadow: inset 0 -3em 3em rgba(253, 252, 252, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(28, 4, 240, 0.3);
    height: 30vh;
    width: 100%;
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;   
    border-radius: 10px;
}

.pichaz{
    margin-left: 5%;
    width: 80%;
    height: 50%;

}
.chini{
    border-left: 4px solid rgba(4, 4, 252, 0.5);
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    background: rbg(33, 22, 228);
    background: linear-gradient(90deg, rgba(33, 22, 228, 0.2) 1%, 
    rgba(138, 138, 246, 0.17841877081455326) 53%, 
    rgba(117, 77, 255, 0) 100%);
    height: 40%;
}
.manenoz{
    margin-top: 10%;
    font-size: 1.5vw;
    color: white;
    width: 100%;
}

@media only screen and (max-width: 600px){
    .card{
        height: 20vh;
    }
    .manenoz{
        font-size: 3vw;
    }    
}