.chats{
    width: 98%;
    padding: 1%;
    height: 100vh;
    background-color: black;
    display: flex;
}
.chatlist{
    width: 38%;
    padding: 1%;
    height: 100%;
    border: 1px solid green;
    border-radius: 10px;
    

}
.realchat{
    width: 98%;
    padding: 1%;
    height: 100%;
    border: 1px solid green;
    border-radius: 10px;
}
.intro{
    width: 100%;
    height: 10vh;
    background-color:  rgb(24, 22, 29);
    align-items: center;
}
.intro h2{
    width: 100%;
    text-align: center;
    color: white;
    padding-top: 5%;
}
.list{
    width: 100%;
    height: 100%;
}
.bar{
   
    margin-top: 2%;
    height: 8vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.imeji{
    object-fit: contain;
    width: 20%;
    border-radius: 50%;
}
.mido{
    width: 60%;
    height: 8vh;

}
.mido h2{
    color: white;
    font-weight: bold;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.5vw;
    margin-top: -.5%;
}
.mido p{
    color: gainsboro;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: -3%;    
}
.last{
    width: 15%;
    
}
.time{
    color: green;
    font-weight: bolder;
    font-size: 1vw;
    margin-top: 7%;
}
.num{
    width: 30%;
    padding-left: 12%;
    margin-left: 7%;    
    background-color: green;
    color: black;
    border-radius: 50%;
    font-size: 1vw;
}
.tops{
    height: 10vh;
    background-color: rgb(24, 22, 29);
    width: 100%;
    
}
.realtop{
    width: 100%;
    display: flex;
}

.imeji2{
    object-fit: cover;
    width: 5%;
    height: 5%;
    border-radius: 50%;
}

.realtop h2{
    color: white;
    font-size: 1.5vw;
    margin-top: 1%;
    margin-left: 1vw;
}

.online{
    display: flex;
    width: fit-content;
    margin-top: -1.5%;
    padding-left: 1vw;
}

.doti{
    margin-top: 30%;
    width: .5vw;
    height: .5vw;
    background-color: black;
    border-radius: 50%;
    border: 1px solid gainsboro;
}

.line{
    color: white;
    font-size: 1vw;
    margin-left: .25vw;
}

.lefts{
    width: 100%;
    display: flex;
    justify-content: left;
}

.bubble1{
    margin-top: 2%;
    width: fit-content;
    padding-left: 0.75%;
    padding-right: 0.75%;
    padding-top: 0.15%;
    padding-bottom: 0.15%;
    align-self: flex-end;
    background-color: blue;
    border-radius: 8%;
}

.bubble2{
    margin-top: 2%;
    width: fit-content;
    padding-left: 0.75%;
    padding-right: 0.75%;
    padding-top: 0.15%;
    padding-bottom: 0.15%;
    background-color:  rgb(44, 44, 46);
    border-radius: 8%;
}

.bubble1 h2{
    color: white;
    font-size: 1vw;

}
.bubble1 h3{
    color: gainsboro;
    font-size: 0.75vw;
}
.rights{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.bubble2 h2{
    color: white;
    font-size: 1vw;

}
.bubble2 h3{
    color: gainsboro;
    font-size: 0.75vw;
}
