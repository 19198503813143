.wall{
    width: 98%;
    height: fit-content;
    padding: 1%;
    font-family: 'Times New Roman', Times, serif;
    display: flex;
}
.wall2{
    width: 98%;
    height: fit-content;
    padding: 1%;
    font-family: 'Times New Roman', Times, serif;
    background-color: rgba(246, 246, 253, 0);
    animation: blackout 2s infinite ease-in;
    display: flex;
}
@keyframes blackout{
    0%{
        opacity: 0.2;
        background-color: rgba(246, 246, 253, 0.8);
    }
    10%{
        opacity: 0.4;
        background-color: rgba(246, 246, 253, 0.6);
    }
    30%{
        opacity: 0.6;
        background-color: rgba(246, 246, 253, 0.4);
    }
    40%{
        opacity: 0.8;
        background-color: rgba(246, 246, 253, 0.2);
    }
    50%{
        opacity: 1;
        background-color: rgba(246, 246, 253, 0);
    }
    60%{
        opacity: 0.8;
        background-color: rgba(246, 246, 253, 0.2);
    }
    70%{
        opacity: 0.6;
        background-color: rgba(246, 246, 253, 0.4);
    }
    80%{
        opacity: 0.4;
        background-color: rgba(246, 246, 253, 0.6);
    }
    90%{
        opacity: 0.2;
        background-color: rgba(246, 246, 253, 0.8);
    }
    100%{
        opacity: 0;
        background-color: rgba(246, 246, 253, 1);
    }
}
.leftas{
    width: 25%;
    height: fit-content;
    display: block;
}

.hello{
    border-left: 4px solid rgba(4, 4, 252, 0.5);
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    background: rbg(33, 22, 228);
    background: linear-gradient(90deg, rgba(33, 22, 228, 0.2) 1%, 
    rgba(138, 138, 246, 0.17841877081455326) 53%, 
    rgba(117, 77, 255, 0) 100%);
    width: 100%;
    height: fit-content;
    color: white;
    font-size: 1.75vw;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.habari{
    border-left: 4px solid rgba(4, 4, 252, 0.5);
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    background: rbg(33, 22, 228);
    background: linear-gradient(90deg, rgba(33, 22, 228, 0.2) 1%, 
    rgba(138, 138, 246, 0.17841877081455326) 53%, 
    rgba(117, 77, 255, 0) 100%);
    color: white;
    font-size: 1.9vw;
    width: 100%;
    text-align: center;
}
.rights{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
}

.nyumba{
    width: 50%;
    height: fit-content;
    border-left: 1px solid rgba(21, 21, 105, 0.5);
    border-right: 1px solid rgba(21, 21, 105, 0.5);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.select_menu{
    margin-top: 10%;
    margin-left: 6%;
    height: fit-content;
    width: 82%;
    padding: 1%;
    position: relative;    
}

.stroke{
    color: white;
    border: 1px solid blue;
    width: 100%;
    padding-top: 1%;
    padding-bottom: 1%;
    text-align: center;
    font-size: 1.4vw;
}

.overflows{
    height: fit-content;
    width: 90%;
    position: absolute;
    z-index: 5;
    top: 8vh;
    left: 0.5vw;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;   
}


.select_button{
    margin-top: 0%;
    cursor: pointer;
    padding-left: 0.5%;
    padding-right: 0.5%;
    width: 100%;
    padding-top: 3%;
    text-align: center;
    height: 5vh;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    background-color:  rgba(247, 247, 252, 0.3);
    border: 1px solid rgb(21, 21, 105);
}
.select_button span{
    font-size: 1.25vw;
    padding-left: 1%;
    color: #f2f2f2;
}
.select_button.active .down{
    transform: rotate(180deg);
}

.list{
    
    width: 100%;
    height: fit-content;
    padding-top: -5%;
    margin-left: -2.5vw;
}

.options{
    padding-left: 1%;    list-style: none;
    text-decoration-style: none;
    width: 90%;
    font-size: 1.25vw;
    height: 4vh;
    background-color:  rgba(247, 247, 252, 0.5);
    border: 1px solid rgb(21, 21, 105);
    cursor: pointer;
    transition: 0.1s ease-in;
    transform-origin: top left;
    transform: translatex(-100px);
    margin-top: -5vh;
    scale: 0;
    opacity: 0;
    transition-delay: calc(var(--i) * 0.05s)
}

.options2{
    padding-left: 1%;    list-style: none;
    text-decoration-style: none;
    width: 90%;
    font-size: 1.25vw;
    height: 4vh;
    background-color:  grey;
    border: 1px solid rgba(21, 21, 105, 0.5);
    cursor: pointer;
    transition: 0.1s ease-in, hover 0 ;
    transform-origin:  left top;
    transform: translatex(0);
    margin-top: 0;
    opacity: 1;
    scale: 1;
    transition-delay: calc(var(--i) * 0.1s);
}


.options2:hover{
    background: #f2f2f2;
}

.options2:nth-child(1){
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.options2:last-child{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.round{
    margin-top: 2vh;
    width: 4vw;
    height: 6vh;
    border-radius: 10px;
    border: 1px solid rgb(21, 21, 105);
    background-color:  rgba(247, 247, 252, 0.3);
}

.kitaa{
    margin-left: 4%;
    margin-top: -2vh;
    width: 90%;
    height: fit-content;
    transition: 0.5s ease-in;
}
.kitaa2{
    width: 0;
    height: 0;
    transition: 0.5s ease-in;

}

.kitaa h4{
    border-left: 4px solid rgba(4, 4, 252, 0.5);
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-left: 2vw;
    margin-top: 10%;
    width: 90%;
    height: fit-content;
    text-align: left;
    font-size: 1.4vw;
    color: white;
    margin-bottom: 1%;
    background: rbg(33, 22, 228);
    background: linear-gradient(90deg, rgba(33, 22, 228, 1), 1%,
    rgba(138, 138, 246, 0.17841877081455326) 53%,
    rgba(99, 62, 229, 0.47115384615384615) 100% );

}

.strita{
    width: 90%;
    font-size: 1.25vw;
    height: 5vh;
    margin-left: 4%;
    padding: 1%;
    border-radius: 8px;
    color: white;
    background-color:  rgba(247, 247, 252, 0.3);
    border: none;
}


.down{
    font-size: 2w;
   
    transition:  0.3s ease-in-out;
}

.ups{
    font-size: 2vw;
    color:  white;
    transform: rotate(180deg);
    transition: 0.5s ease-in;    
}

.search{
    color: white;
    font-size: 2vw;
    margin-left: 20%;
    margin-top: 15%;
}

.radios{
    border-left: 4px solid rgba(4, 4, 252, 0.5);
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 1%;
    background: rbg(33, 22, 228);
    background: linear-gradient(90deg, rgba(33, 22, 228, 0.2) 1%, 
    rgba(138, 138, 246, 0.17841877081455326) 53%, 
    rgba(117, 77, 255, 0) 100%);
    width: 80%;
    display: flex;
    margin-left: 6%;

}

.radios h2{
    color: white;
    margin-left: 2%;
    width: 80%;
    text-align: left;
    font-size: 1.5vw;
}

.check{
    
    width: 5%;
    color: blue;
    background-color: black;
    border: 1px solid rgba(4, 4, 252, 0.5);
}
.imeij{
     border: 1px solid rgba(238, 238, 245, 0.5);
     border-radius: 10px;
    margin-left: 8%;
    width: 80%;
    padding-top: 2%;
    margin-top: 6%;
    height: fit-content;
    display: flex;
}

.imeij h4{
    margin-top: 0;
    color: white;
    font-size: 1.5vw;
    width: 78%;
    margin-left: 2%;
    text-align: left;
}
.iconx{
    cursor: pointer;
    width: fit-content;
    margin-top: -1%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 1%;
    height: 100%;
    color: white;
    font-size: 2vw;
    background-color: rgba(4, 4, 252, 0.2);
    border-radius: 10px;
}

.hide{
    display: none;
}

.relativ{
    position: relative;
    width: 50%;
    margin-left: 10%;
    height: fit-content;
}
.xicon{
    position: absolute;
    top: 1%;
    left: 1%;
    color: red;
    cursor: pointer;
}
.uploaded{
    width: 100%;
    object-fit: contain;
}
.subs{

    width: 49%;
    padding: 1%;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    font-size: 2vw;
    background-color:  rgba(253, 253, 255, 0);
    border: 1px solid rgba(253, 253, 255, 0.5);
    transition: 0.3s ease-in;
    margin-left: 25%;
    margin-top: 10%;
}
.subs:hover{
    background-color: rgba(247, 247, 252, 0.3);
}

.neighbour{   
    margin-top: 4%;
    height: fit-content;
    width: 25%;
    display: block;
    
}

.use{
    background: rgb(180, 144, 177);
    background: radial-gradient(circle, rgba(180, 144, 177, 0.20833333333333337) 0%,
    rgba(238, 174, 202, 0.3846153846153846) 0%,
    rgb(38, 11, 236) 0%,
    rgba(28, 5, 99, 0.5),
    rgba(47, 74, 118, 0.5) 99%,
    rgba(19, 59, 105, 0) 100%);
    margin-top: 50%;
    margin-left: 5%;
    width: 90%;
    height: fit-content;
    padding: 1%;
    box-shadow: inset 0 -3em 3em rgba(253, 252, 252, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(28, 4, 240, 0.3);
}
.use h3{
    color: white;
    font-size: 2vw;
    width: 100%;
    text-align: center;
}
.use h5{
    color: #fff;
    width: 100%;
    height: fit-content;
    margin-top: 2%;
    font-size: 1.75vw;
    line-height: 5vh;
    text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
}

.signs{
    width: 100%;
    height: 50vh;
    justify-content: center;
    align-items: center;
}
.signs h2{
    margin-top: 10%;
    color: white;
    font-size: 2vw;
    width: 100%;
    text-align: center;
}

.signs button {
    margin-top: 10%;
    width: 48%;
    margin-left: 25%;
    position: absolute;
    top: 80vh;
    left: 0;
    font-size: 3vw;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    padding: 1%;
    border-radius: 10px;
}


.signs button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.signs button:active {
    color: #000
}

.signs button:active:after {
    background: transparent;
}


.signs button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}


.lord{
    opacity: 1;
    position: fixed;
    z-index: 50;
    top: 40vh;
    left: 25vw;
    width: 48%;
    height: fit-content;
    padding: 2%;
    background-color: rgba(7, 7, 7, 0.7);
    border-radius: 8px;
    border: 1px solid blue;
    transition: 0.5s ease-in;
    
}
.lord2{
    position: fixed;
    z-index: 50;
    top: 40vh;
    left: 25vw;
    opacity: 0;
    width: 0;
    height: 0;
    transition: 0.5s ease-in;
}
.lord h4{
    width: 100%;
    text-align: center;
    color: white;
    font-size: 3vw;
    text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
}



@media only screen and (max-width: 600px){
    .overflows{
        top: 4.5vh;
    }
    .neighbour{
        display: none;   
    }
    .leftas{
        display: none;
    }

    .nyumba{
        width: 100%;
    }

    .select_button span{
        font-size: 4vw;
    }

    .options2{
        font-size: 4vw;
    }

    .kitaa h4{
        font-size: 4vw;
        text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
    }

    .strita{
        font-size: 4vw;
    }

    .search{
        font-size: 4vw;
    }

    .radios h2{
        font-size: 4vw;
    }
    .imeij h4{
        font-size: 4vw;
    }
    .signs h2{
      font-size: 4vw;
    }
    .signs button{
        margin-top: 20%;
        font-size: 5vw;
    }
    .lord{
        width: 60%;
        left: 20vw;
    }
    .lord h4{
        font-size: 4vw;
    }
    .iconx{
        font-size: 5vw;
    }
    .stroke{
         font-size: 4vw;
    }
    .hello{
        font-size: 4vw;
        text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
    }
    .habari{
        font-size: 4vw;
    }
    .subs{
        font-size: 5vw;
    }
    

}
