*{
    transition: all 0.2s ease-out;
}

.bodys{    
    width: 100%;
    height: fit-content;
    padding-top: 5%;    
    margin-top: -2%;
    color: #fff;
    overflow: hidden;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.wapi{
    width: 100%;
    height: fit-content;
    font-size: 2.5vw;
    text-align: center;
    padding: 1%;
    text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
}

.top{
    margin-top: 0;
    width: 99%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
}

.labo{
    margin-top: 0;
    color: white;
    font-size: 2.25vw;
    padding: 1%;
    border: 1px solid blue;
    border-radius: 15px;    
}

  .nyuma{
    width: fit-content;
    height: fit-content;
    padding: 0.5%;
    border: 1px solid blue;
    border-radius: 10px;
    transition: 0.5s ease-in;
    cursor: pointer;
}

.nyuma:hover{
    background-color: rgba(180, 144, 177, 0.3);
}

.namba{
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 3vw;
    padding: 1%;
    border-radius: 40%;
    background: rgb(7, 7, 7);
    background: radial-gradient(circle, rgba(0, 0, 0, 0.208) 0%,
    rgba(3, 3, 3, 0.385) 0%,
    rgba(38, 11, 236, 0.3) 0%,
    rgba(28, 5, 99, 0.5),
    rgba(5, 5, 5, 0.5) 99%,
    rgba(0, 0, 0, 0) 100%);
}

.trans{
    width: 100%;
    height: fit-content;
    
    transition: 0.5s ease-in;
}

.visible{
    width: 100%;
    height: fit-content;
    transition: 0.5s ease-in;    
}

.panam{
    position: relative;
    width: 100%;
    height: 80vh;
    margin-top: 0;
    margin-bottom: 4%;
}

.slideCont{
    width: 100%;
    height: 100%;
    
    display: grid;   
    > .dark{
        grid-area: 1 / -1;
    }
    align-items: center;
    justify-content: center;   
}

.dark{
    transition: 0.5s ease-in-out;
    transform: translateX(calc( var(--offset) * 100%));
    border: 1px solid blue;
    height: 100%;
    width: 100vw;
    background-position: center center;
    background-size: cover;
}

.dark[data-active]{
    transition: 0.5s;
}

.threeD{   
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    row-gap: 5vw;
    > .slidez {
        grid-area: 1 / -1;
    }
    transform-style: preserve-3d;
    justify-content: center;
    align-items: center;  
    perspective: 600px;
}


.slidez{
    color: black;
    width: 26vw;
    height: 40vh;
    text-align: center;
    transform: translateX(calc( 100% * var(--offset))) rotateY(calc( -40deg * var(--dir)));
    background: inherit;    
    background-size: cover;
    background-position: center center;
    --x: calc(var(--px) - .5);
    --y: calc(var(--py) - .5);
    transition: transform 0.5s ease-in-out;
    transform-style: preserve-3d;
    border: 1px solid  rgba(42, 168, 110, 0.5);
    &::before{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }
    
}
    

.slidez[data-active] {
    width: 30vw;
    transition: 0.3s ease-in;
    border: 3px solid black;
    --x: calc(var(--px) - .5);
    --y: calc(var(--py) - .5);
    transform: perspective(1000px);

      .conts{opacity: 1;
        
        transition: opacity 0.3s linear;
        --x: calc(var(--px) - .5);
        --y: calc(var(--py) - .5);
        perspective: 300px;
        transform-style: preserve-3d;       
    } 
      
      &::before{
        display: none;
      }    
      &:hover{
        transform: rotateX(calc(var(--y) * -45deg))  rotateY(calc(var(--x) * 45deg)); 
        
        transition: 0.1s ease-in;   
    }

}   

.conts{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    transform: translateZ(3rem);
    opacity: 0;
    transition: 0.5s opacity linear;        
}

&:hover{
    transition: none;
    
}

.see{
    height: 10vh;
    width: 15vw;
}
.name{
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    text-align: left;
    font-size: 4vw;
    color: wheat;
    
    
}
.desc{
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    text-align: left;
    font-size: 2vw;
    color: wheat;
    text-shadow: 0.1rem 1rem 1rem #000;

    
    
}

.buttns{
    width: 60%;
    height: 4vh;
    position: absolute;
    top: 80%;
    left: 20%;
    display: flex;
    justify-content: space-evenly;
   

}
.butt{
    font-size: 1.5vw;
    width: fit-content;
    height: fit-content;
    padding: 1%;
    cursor: pointer;
    color: white;
    border: 1px solid white;
    border-radius: 8px;
    background-color: rgba(254, 255, 255, 0.1);
    transition: 0.3s ease-in;
}
.butt:hover{
    background-color: rgba(254, 255, 255, 0.5);
}

.realDark{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
}

.lefts{
    height: fit-content;
    width: 50%;
}

.right{
    margin-left: 10%;
    width: 40%;
    height: 100%;
   }

 .rate{
    font-size: 1.5vw;
    margin-top: 90%;
 }

.cheki{
    color: white;
    font-size: 1.75vw;
    width: 80%;     
    text-align: center;
    background-color:  rgba(15, 15, 235, 0.3);
    cursor: pointer;
}

.cheki{
    color: white;
    font-size: 1.75vw;
    width: 80%;     
    text-align: center;
    background-color:  rgba(15, 15, 235, 0.3);
    cursor: pointer;
}

.cheki2{
    text-decoration: none;
    font-size: 1.75vw;
    width: 80%;     
    text-align: center;
    background-color:  rgba(15, 15, 235, 0.3);
    cursor: pointer;
}



.box{
    width: 60%;
    height: 4vh;
    display: flex;
    margin-top: 2%;  
}
.box h4{
    color: white;
    white-space: nowrap;
    font-size: 1.2vw;
    width: 80%;
    margin-left: 2%;
    text-align: left;
    text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
    margin-top: 0;
    letter-spacing: 0.1vw;
}
.icon{
    font-size: 1.5vw;
    padding-top: 16%;
    color: white;
    text-shadow: 1px 1px 1px #000;
    
}

.upande{
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;

}
.upande h2{
    padding: 1%;
    color: white;
    text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
    width: 80%;
    text-align: center;
    font-size: 2vw;
}

.pango{
    width: 100%;
    height: fit-content;
    border-bottom: -5%;
    margin-top: -3%;
    background: rgb(10,10,10);
background: linear-gradient(0deg, rgba(10,10,10,0.9220063025210083) 29%, rgba(32,49,216,0.48503151260504207) 73%, rgba(0,0,0,0.7931547619047619) 98%);
}

.jumla{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
}
.cover{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding-bottom: 0;
    height: fit-content;
    
}
.topz{
    width: fit-content;
    height: fit-content;
    
    font-size: 2vw;
    margin-bottom: -2vh;
}


.navy2{
    letter-spacing: 0.2vw;
    width: fit-content;
    color: white;
    text-align: center;
    font-size: 1.25vw;
    transition: 0.5s ease-in;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}

.keys{
    box-shadow: inset 0 -3em 3em rgba(10, 70, 236, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(28, 4, 240, 0.3);

    padding-top: 1%;
    width: 98%;
    display: flex;
      margin-left: 1%;
    margin-bottom: 2%;
    height: fit-content;
    border-radius: 8px;
    opacity: 1;
    transition: 0.5s ease-in;
    transform-origin: top;
}
.keys2{
    width: 0;
    height: 5vh;
    transform: translateY(-50px) scale(0.1) ;
    transition: 0.5s ease;
    transform-origin: top;
    opacity: 0;
    box-shadow: inset 0 -3em 3em rgba(10, 70, 236, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(28, 4, 240, 0.3);
    
}

.icona{
    color: white;
    font-size: 4vw;
    margin-left: 0.5%;
    margin-bottom: -2%;
    cursor: pointer;
    transition: 0.5s;
}
.icona:hover{
    scale: 1.25;
}
.meaning{
    margin-left: 5%;
    width: 50%;
    height: fit-content;
    max-height: 50vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    transition: 0.5s ease-in;
}
.meaningz{
    margin-left: 5%;
    width: 10%;
    transform: translateY(-50px) scale(0.1) ;
    transition: 0.5s ease;
    
    height: fit-content;
    max-height: 50vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.means{
    display: flex;
    width: 25%;
    height: fit-content;
}
.con{
    font-size: 1.5vw;
    color: white;
}
.meaning2{
    
    width: 25%;
    height: fit-content;

}
.meaning2Z{
    width: 5%;
    transform: translateY(-50px) scale(0.1) ;
    transition: 0.5s ease;
    
}
.rates{
    width: 100%;
    display: flex;
    margin-top: 2%;
    border: 1px solid rgba(37, 236, 186, 0.2);
}
.ratz{
    
    border: 1px solid rgba(37, 236, 186, 0.2);

}
.Rt{
    padding-top: 1%;
    width: 80%;
    text-align: left;
    font-size: 1.25vw;
    padding-left: 1%;
}
.con3{
    font-size: 1.5vw;
    color: white;
    margin-left: 3%;
}

.neighbour{
    place-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 4%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}    

@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.neighbourz {
    --size: 100%;

    --speed: 50s;

    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

    width: var(--size);

    height: var(--size);

    filter: blur(calc(var(--size) / 5));

    background-image: linear-gradient(hsl(158, 82, 57, 85%));

    animation: rotate var(--speed) var(--easing) alternate infinite;

    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

@media (min-width: 720px){
    .gradient{
        --size: 500px;
    }
}

.use{
    background: rgb(180, 144, 177);
    background: radial-gradient(circle, rgba(180, 144, 177, 0.20833333333333337) 0%,
    rgba(238, 174, 202, 0.3846153846153846) 0%,
    rgb(38, 11, 236) 0%,
    rgba(28, 5, 99, 0.5),
    rgba(47, 74, 118, 0.5) 99%,
    rgba(19, 59, 105, 0) 100%);
    width: 30%;
    height: fit-content;
    padding: 1%;
    box-shadow: inset 0 -3em 3em rgba(253, 252, 252, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(28, 4, 240, 0.3);
}
.use h3{
    color: white;
    font-size: 2vw;
    width: 100%;
    text-align: center;
}
.use h5{
    color: #fff;
    width: 100%;
    height: fit-content;
    margin-top: 2%;
    font-size: 1.75vw;
    text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
}

@media only screen and (max-width: 1000px){
    .topz{
        font-size: 3vw;
        margin-bottom: -2vh;
    }
    .navy2{
        width: fit-content;
        color: white;
        text-align: center;
        font-size: 3vw;
        transition: 0.5s ease-in;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
    }
}
@media only screen and (max-width: 600px){
    .bodys{
        padding-top: 8%;  
    }  
    .top{
        margin-top: 8%;
    }
    .nyuma{
        border-radius: 8px;
        padding: 2%;
    }
    .topz{
        font-size: 4vw;
        margin-bottom: -1.5vh;
    }
    .navy2{
        width: fit-content;
        color: white;
        text-align: center;
        font-size: 2.5vw;
        transition: 0.5s ease-in;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
    }
    .labo{
        font-size: 4vw;
        border-radius: 8px;
        padding: 2%;
    }
    .namba{
        font-size: 4vw;
    }

    .keys{
        box-shadow: inset 0 -3em 3em rgba(253, 252, 252, 0.2), 0 0 0 2px rgb(14, 13, 13), 0.3em 0.3em 1px rgba(255, 253, 253, 0.3);
        padding-top: 1%;
        width: 98%;
        display: flex;
        flex-wrap: wrap;
        margin-left: 1%;
        margin-bottom: 2%;
        height: fit-content;
        border-radius: 8px;
        opacity: 1;
        transition: 0.5s ease-in;
        transform-origin: top;
    }  

    .icona{
        color: white;
        font-size: 4vw;
        margin-left: 0.5%;
        margin-bottom: -2%;
        cursor: pointer;
        transition: 0.5s;
    }

    .icona:hover{
        scale: 1.25;
    }

    .meaning{
        margin-left: 5%;
        width: 90%;
        height: fit-content;
        max-height: 50vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        transition: 0.5s ease-in;
    }    

    .means{
        display: flex;
        width: 25%;
        height: fit-content;
    }

    .con{
        font-size: 2vw;
        color: white;
    }

    .con2{
        font-size: 2vw;
    }

    .con3{
        font-size: 2vw;
    }

    .Rating{
        font-size: 2vw;
    }

    .meaning2{
        
        width: 80%;
        height: fit-content;
        margin-left: 5%;
    
    }

    .rates{
        width: 100%;
        display: flex;
        margin-top: 2%;
    }

    .ratz{
        font-size: 2vw;
        border: 1px solid rgba(37, 236, 186, 0.2);
    
    }

    .upande h2{
        font-size: 3.5vw;
        margin-top: 0;
        white-space: nowrap;
    }
   

    .panam{
        height: 60vh;
    }

    .buttns{
        width: 60%;
        height: 4vh;
        position: absolute;
        top: 90%;
        left: 20%;
        display: flex;
        justify-content: space-between;
    }
    
    .box{
        width: 60%;
        height: 4vh;
        display: flex;
        margin-top: 2%;  
    }
    
    .box h4{
        color: white;
        white-space: nowrap;
        font-size: 2vw;
        width: 80%;
        margin-top: 0;
         margin-left: 2%;
        text-align: left;
        text-shadow: 1px 1px 2px black, 0 0 1em blue, 0 0 0.2em blue;
        letter-spacing: 0.1vw;
    }

    .icon{
        width: fit-content;
        height: fit-content;
        font-size: 2.5vw;
        padding-top: 23%;
        color: white;
        text-shadow: 1px 1px 1px #000;
    }

    .slidez{
        color: black;
        width: 35vw;
        height: 40vh;
    }  

    .slidez[data-active]{
        width: 35vw;
    }

    .right{
        margin-left: 10%;
        width: 40%;
        height: 100%;
    }

    .rate{
        font-size: 3vw;
        margin-top: 270%;
    }  

    .cheki{
        color: white;
        font-size: 3vw;
        width: 80%;     
        text-align: center;
        background-color:  rgba(15, 15, 235, 0.3);
        cursor: pointer;
    }     
    .use{
        width: 90%;
    }
    .use h3{
       font-size: 4vw;
    }    
    .use h5{
        font-size: 3vw;
    }
    .wapi{
        font-size: 4vw;
    }
}



@media only screen and (max-width: 450px){
       .navy2{
        font-size: 3.25vw;
        padding-bottom: 1%;
       }
       .panam{
        height: 52vh;
       }
       .slidez{
        width: 50vw;
       }
       .slidez[data-active]{
        width: 50vw;
       }
       .icon{
        font-size: 1vw;
       }
       .box h4{
         font-size: 2.5vw;
       }

}